@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');  

/*================GOogle Translator Start====================*/
.goog-te-menu-value span:nth-child(0), 
.goog-te-menu-value span:nth-child(2), 
.goog-te-banner-frame.skiptranslate {
    display:none !important;
  }

div#google_translate_element div.goog-te-gadget-simple {
    border: .2px solid #0000dc;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 7px;
    background-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*================GOogle Translator End====================*/



html {
    scroll-behavior: smooth;
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', cursive;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
} 

body {
    top: 0px !important; 
}


/*Gblox section*/
div#gbloxBackM {
    background-image: url('../images/gblox_bg.png');
    background-size: cover;
    height: 100vh;
}

h2#servicesHeadM {
    font-size: 40px;
}
   
p#servicesTextM {
    color: #757575;
    font-size: 18px;
}

div#expMainBack {
    background-color: #0000DC;
    background: linear-gradient(
    to bottom,
    #0000DC 0%,
    #0000DC 60%,
    #0D0F45 40%,
    #0D0F45 100%
  );
}

h3#expHead {
    font-size: 42px;
    font-weight: bold;
}

div#secOne {
    background-image: url('../images/sec1Img.png');
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
}

div#secOneRow {
    margin-top: 3.5rem;
}

h4#secOneHead {
    font-size: 34px;
    color: #fff;
    margin-left: 8rem;
}

p#secOneSub {
    color: #fff;
    margin-left: 8rem;
    font-size: 18px;
}

div#secTwo {
    background-image: url('../images/sec2Img.png');
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
}

h4#compHead {
    font-size: 34px;
    color: #fff;
}

h4#startHead {
    font-size: 34px;
    color: #fff;
}

div#featCard {
    background-color: #903EE4;
    box-shadow: rgba(132, 128, 255, 0.2) 0px 8px 24px;
    border-radius: 30px;
    border: 0px;
}

p#featSub {
    font-size: 25px;
}

a#buyMingo {
    text-decoration: none;
    color: #fff;
    background-color: #FFC400;
    border-radius: 30px;
    padding: 10px 50px;
    transition: all 0.3s;
}

a#buyMingo:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 47px;
}

























/*========================================= Mobile Responsiveness Portrait Start ================================================*/
@media only screen  
  and (max-device-width: 500px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {



    /*Gblox section*/
    div#gbloxBackM {
        height: 90vh;
    }

}