@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');  

/*================GOogle Translator Start====================*/
.goog-te-menu-value span:nth-child(0), 
.goog-te-menu-value span:nth-child(2), 
.goog-te-banner-frame.skiptranslate {
    display:none !important;
  }

div#google_translate_element div.goog-te-gadget-simple {
    border: .2px solid #0000dc;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 7px;
    background-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*================GOogle Translator End====================*/



html {
    scroll-behavior: smooth;
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', cursive;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
} 

body {
    top: 0px !important; 
}

div#textHolder {
    margin-top: 6rem;
}

h3#signHead {
    font-size: 40px;
    font-weight: bold;
}

p#signSub {
    color: #747474;
    font-size: 16px;
}

div#formsContainer {
    width: 400px;
}

div#welcomeDiv {
    width: 400px;
}

a#signIn-btn {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 5px 70px;
    border-radius: 50px;
    font-size: 18px;
    display: block;
}

a#signIn-btn:hover {
    color: #fff;
    background-color: #ebb401;
}

div#footHolder {
    width: 400px;
    margin-top: 7rem;
}