@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');  

/*================GOogle Translator Start====================*/
.goog-te-menu-value span:nth-child(0), 
.goog-te-menu-value span:nth-child(2), 
.goog-te-banner-frame.skiptranslate {
    display:none !important;
  }

div#google_translate_element div.goog-te-gadget-simple {
    border: .2px solid #0000dc;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 7px;
    background-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*================GOogle Translator End====================*/



html {
    scroll-behavior: smooth;
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', cursive;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
} 

body {
    top: 0px !important; 
}

body.swal2-shown > [aria-hidden="true"] {
    transition: 0.1s filter;
    filter: blur(3px);
}

#choose_country {
    width: 200px;
    padding: 5px;
    border-color: #646464;
    border-radius: 30px;
}
  


/*========================== Navbar Start ==========================*/

a.nav-link {
    color: #000 !important;
    margin-right: 1.5rem;
    font-size: 14px;
}

a.nav-link:hover {
    color: #0000dc !important;
}

#dropdownPro:hover #dropdown-menu {
    display: block;
    margin-top: .5rem;
    position: absolute;
    left: 10rem; 
}

#dropdownPro2:hover #dropdown-menu {
    display: block;
    margin-top: .5rem;
    position: absolute;
     
}

span#orderNum {
    position: relative;
    right: 10px;
    top: -10px;
    border-radius: 100%;
    color: #fff;
    background-color: red;
    padding: .5px 7px;
    font-size: 12px;

}

div#navCardMain {
    background-color: #903ee4;
    border-radius: 20px;
    border: 0px;
}

div#navCard {
    border-radius: 15px 15px 20px 20px;
    background-color: #f8f8f8;
    padding: 40px 20px;
}

div#navCardMain2 {
    background-color: #3fb6f3;
    border-radius: 20px;
    border: 0px;
}

div#navCard2 {
    border-radius: 15px 15px 20px 20px;
    background-color: #f8f8f8;
    padding: 23px 20px;
}

a#signin-btn {
    border: 1px solid #ffc400;
    padding: 5px 30px;
    border-radius: 30px;
    text-decoration: none;
}

a#signin-btn:hover {
    background-color: #ffc400;
    color: #fff !important;
}

#userIcon {
    text-decoration: none;
    color: #646464;
    font-size: 24px;
}

#d_dropdown {
    border: none;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

/*Cart Modal*/

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(100%, 0)scale(1);
    transform: translate(100%, 0)scale(1);
  }

  .modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate3d(0, 0);
    transform: translate(0, 0);
    display: flex;
    height: 100%;
    position: relative;
    margin: auto;
    float: right;
    width: 500px;
  }
  

.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}


.modal-content {
    border-radius: 0;
    border: none;
}
/*Cart Modal End*/

/*========================== Navbar End ==========================*/


/* CAROUSEL*/
div#carousel-caption1 {
    position: absolute;
    bottom: 0;
    top: 8rem;
    left:20;
    right:0;
}

div#headDiv {
    width: 600px;
    text-align: left;
}

div#carousel-caption2 {
    position: absolute;
    bottom: 0;
    top: 8rem;
    left:20;
    right:0;
}

div#headDiv2 {
    width: 600px;
    text-align: left;
}

div#carousel-caption3 {
    position: absolute;
    bottom: 0;
    top: 8rem;
    left:20;
    right:0;
}

div#headDiv3 {
    width: 600px;
    text-align: left;
}

h2#carouselHead {
    color: #fff;
    font-size: 50px;
    font-weight: bold;
}

div#pDiv {
    width: 400px;
}

div#btnDiv {
    width: 400px;
}

a#learnMore-btn {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 8px 70px;
    border-radius: 50px;
    font-size: 25px;
}

a#learnMore-btn:hover {
    color: #fff;
    background-color: #ebb401;
}

.carousel-indicators {
    text-align: right !important;
    float: right !important;
    right: 2% !important;
    right: inherit !important;
    padding-bottom: 5px !important;
    position: absolute;
    bottom: 0;
    top: 40rem;
    left:20;
    right:0;
}

.carousel-indicators > li {
    border-radius: 50%;
    width: 8px !important;
    height: 8px !important;
    margin: 0px 8px !important;
    background-color: #fff !important;
}

.carousel-indicators > li.active {
    background-color: #ffc400 !important;
}
  




/*========================== Sponors Showcase Start ==========================*/


.showcase-box img {
    max-width: 35%;
    margin-top: 1rem;
}

/*========================== Sponsors Showcase End ========================== */


/*=======================Home Page Start=======================*/

a#explore-btn {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    transition: all 0.3s;
}

a#explore-btn:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 37px;
}

a#explore-btn8 {
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    transition: all 0.3s;
}

a#explore-btn8:hover {
    color: #fff;
    background-color: #ebb401;
    border: 1px solid #ebb401;
    padding: 7px 37px;
}

a#explore-btn5 {
    color: #fff;
    border: 1px solid #ffc400;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    margin-left: 1rem;
    transition: all 0.3s;
}

a#explore-btn5:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 37px;
}

a#meet-btn {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    margin-right: 5rem;
    transition: all 0.3s;
}

a#meet-btn:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 37px;
}

div#contentHolder {
    width: 450px;
}

span#roboHead {
    font-size: 40px;
    margin-left: 25px;
}

h3#joinText {
    font-size: 34px;
    font-weight: bold;
}

a#signup-btn {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    transition: all 0.3s;
    /*display: block;*/
}

a#signup-btn:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 37px;
    
}


div#excellentContainer {
    background-image: url("../images/excelleng_bg.png");
}

div#celloContainer {
    background-image: url("../images/cello_bg.png");   
    background-size: cover;
}

div#youtubeBack {
    background-image: url("../images/youtube_bg.png");
    height: 100%;
    background-size: cover;   
}

#theVideo {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

div#tubeCardMain {
    border-radius: 20px;
    min-height: 70vh;
}

div#tubeCard {  
    padding: 80px 50px;
    min-width: 300px;
    max-width: 800px;
}


/*Gblox section*/
div#gbloxBack {
    background-image: url('../images/gblox_bg.png');
    background-size: cover;
    height: 100vh;
}


/*=======================Home Page End=======================*/








/*=======================Services Component Start=======================*/
h2#servicesHead {
 font-size: 40px;
}

p#servicesText {
    color: #757575;
    font-size: 18px;
}

h2#servicesHead8 {
 font-size: 40px;
}

p#servicesText8 {
    color: #fff;
    font-size: 18px;
}

div#serviceCard {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0px;
    border-radius: 20px;
    height: 400px;
    color: #3FB6F3;
}

div#serviceCard:hover {
    background-color: #3FB6F3;
    color: #fff;
}


div#serviceCardBody {
    padding: 15px;
}

h4#serviceTHead {
    font-size: 18px;
}

p#servicesTSub {
    color: #757575;
    font-size: 15px;
}

div#serviceCard2 {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0px;
    border-radius: 20px;
    height: 400px;
    color: #903EE4;
}

div#serviceCard2:hover {
    background-color: #903EE4;
    color: #fff;
}

div#serviceCardBody2 {
    padding: 15px;
}

h4#serviceTHead4 {
    font-size: 20px;
}


div#serviceCard8 {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0px;
    border-radius: 20px;
    height: 400px;
    color: #FF808B;
}

div#serviceCard8:hover {
    background-color: #FF808B;
    color: #fff;
}


h4#serviceTHead2 {
    font-size: 18px;
}

div#serviceCard3 {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0px;
    border-radius: 20px;
    height: 400px;
    color: #15c2a5;
}

div#serviceCard3:hover {
    background-color: #15c2a5;
    color: #fff;
}


h4#serviceTHead3 {
    font-size: 18px;
}



div#exploreDivv {
    position: absolute;
    top: 22rem;
    left: 0;
    right: 0;
}

a#explore-btn2 {
    color: #fff;
    background-color: #ffc400;
    text-decoration: none;
    padding: 10px 40px;
    width: 250px;
    border-radius: 30px;
    transition: all 0.3s;
}

a#explore-btn2:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 7px 37px;
}

/*=======================Services Component Start=======================*/



/*=======================Testimonial Component Start=======================*/
   h3#testyHead {
    font-size: 16px;
    margin-bottom: 2px !important;
   }

   p#testySub {
    color: #757575;
    font-size: 14px;
   }
   
   p#testyText {
       color: #757575;
       font-size: 14px;
   }
   
   div#testyCard {
       background-color: #fff;
       box-shadow: rgba(100, 100, 111, 0.03) 0px 7px 29px 0px;   
       border: 0px;
       border-radius: 20px;
       margin-left: 1rem;
       margin-bottom: 2rem;
   }
   
   div#testyCardBody {
       padding: 25px;
   }
   
    .slick-dots{
        position: absolute;
        display: block;
        width: 100%;
        padding: 10;
        margin: 0;
        list-style: none;
        text-align: left;
        margin-left: 10px;
    }

    .slick-dots li.slick-active button:before{
      font-size: 15px;
      color: #0000FE;
    }
   
/*=======================Testimonial Component Start=======================*/



/*====================================Gallery Start =============================*/
img#galleryImg {
    transition: transform .5s ease;
}

img#galleryImg:hover {
    transform: scale(1.05);
}
/*====================================Gallery End =============================*/


/*=======================Footer Component Start=======================*/

   ul.list-unstyled > li > a {
    text-decoration: none;
    color: #fff;
   }

   a#footLink {
    text-decoration: none;
    color: #fff;
   }


    input#thatName {
        border-radius: 30px 0px 0px 30px;
    }

    input#thatMail {
        border-radius: 0px 0px 0px 0px;
    }

   button#subsBtn {
    border: 1px solid #fff;
    border-radius: 0px 30px 30px 0px !important;
    padding: 4px 30px;
   }

   button#subsBtn:hover {
    background-color: #2c2974 !important;
    color: #fff;
   }

   ul#socialList2 {
    margin-left: -2rem !important;
   }

   img#socialLink {
    margin-right: 8px;
    width: 7%;
    transition: 0.3s ease;
   }

   img#socialLink:hover {
    opacity: 50%;
   }

   div#theFootFields {
        margin-left: 8rem;
        margin-right: 8rem;
   }


/*=======================Footer Component End=======================*/



/*=============================About Page Start================================*/

   div#bannerContent {
        width: 550px;
        position: absolute;
        bottom: 0;
        top: 10rem;
        left: 0;
        right: 0;
        margin: 0 auto;
   }

   p#aboutText {
    color: #757575;
   }



/*=============================About Page End================================*/



/*=============================Contact Page Start================================*/

   div#aboutBanner {
        background-image: url('../images/about_banner.png');
        background-size: cover;
        height: 70vh;
   }

   div#bannerContent2 {
        padding-top: 10rem;
   }

   p#contactText {
    color: #757575;
   }

   div#topCards {
        position: absolute;
        top: 50%;
        left: 20%;
        width: 850px;
   }

   div#cardBody {
    padding: 2.5rem;
   }

   div#contactCard {
        background-color: #fff;
        border: 0px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        height: 230px;
        width: 250px;
   }

   p#contactUs {
        font-weight: bold;
        color: #6666EA;
        margin-top: 2rem;
   }

   a#submitForm-btn {
        color: #6666EA;
        background-color: #fff;
        border: 1px solid #6666EA;
        border-radius: 30px;
        padding: 8px 30px;
   }

   a#submitForm-btn:hover {
        color: #fff;
        background-color: #6666EA;
   }

   #inputMsg {
        height: 200px;
        background-color: #f0eff893;
   }

   #inputName, #inputMail {
        background-color: #f0eff893;
   }

/*=============================Contact Page End================================*/




/*=============================Support Page Start================================*/

div#theLogoDiv {
    position: absolute;
    margin-left: 7rem;
}

span#supText {
    color: #fff;
    font-size: 20px;
    margin-left: 1rem;
}

div#supBannerContent {
    width: 550px;
    position: absolute;
    top: 15%;
    left: 30%;
}

p#supportText {
color: #757575;
}

div#dCard1 {
    background-color: #FF808B;
    border-radius: 15px;
    border: 0px;
}

div#dCard2 {
    background-color: #3FB6F3;
    border-radius: 15px;
    border: 0px;
}

div#dCard3 {
    background-color: #15C2A5;
    border-radius: 15px;
    border: 0px;
}

div#dCard1Pro {
    padding: 50px 100px;
}

div#genCard {
    background-color: #fff;
    border-radius: 15px;
    border: 0px;
    height: 250px;
}

div#genCardPro {
    padding: 30px 10px;
}

/*=============================Support Page End================================*/




























/*========================================= Mobile Responsiveness Portrait Start ================================================*/
@media only screen  
  and (max-device-width: 500px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    /* NAVBAR */
    li.nav-item {
        text-align: center;
    }

    img#theLogo {
        width: 100px;
    }

    #carouselExampleDark {
        margin-top: 2.5rem;
    }

    a#shop-btn {
        color: #fff !important;
        background-color: #FFC400;
        border-radius: 50px;
        padding: 8px 10px;
        width: 100px;
        text-align: center !important;
    }

    #dropdownPro:hover #dropdown-menu {
        display: block;
        margin-top: 0rem !important;
        position: absolute;
        left: -15rem !important;
    }


    /* CAROUSEL*/
    div#slider1Desk, #slider2Desk, #slider3Desk {
        display: none !important;
    }

    div#slider1Mobile, #slider2Mobile, #slider3Mobile {
        display: block !important;
    }

    div#carousel-caption1 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv {
        width: 300px;
    }

    div#carousel-caption2 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv2 {
        width: 300px;
    }

    div#carousel-caption3 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv3 {
        width: 300px;
    }

    h2#carouselHead {
        color: #fff;
        font-size: 25px;
    }

    div#pDiv {
        width: 300px;
        font-size: 14px;
        margin-top: -7rem !important;
        padding-top: 5rem;
    }

    div#btnDiv {
        width: 130px;
        margin-top: -1rem;
    }

    a#learnMore-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 4px 8px;
        border-radius: 30px;
        font-size: 12px;
        display: block;
    }

    a#learnMore-btn:hover {
        color: #fff;
        background-color: #ebb401;
    }

    .carousel-indicators {
        display: none;
    }

    /*Gblox section*/
    div#gbloxBack {
        height: 80vh;
    }

    a#explore-btn8 {
        padding: 10px 20px;
        font-size: 12px;
    }

    a#explore-btn {
        font-size: 12px;
    }




    /* SPONSORS*/
    div#sponsors {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .showcase-box > img {
        width: 40% !important;
    }

    div#youtubeBack {
        background-image: url("../images/youtube_bg.png");
        height: 125vh !important;   
    }
    
    #theVideo {
        width: 100%;
        height: 40vh;
        margin-top: 1rem !important;
        border-radius: 30px;
    }

    div#tubeCardMain {
        border-radius: 20px;
        min-height: 40vh;
    }

    div#tubeCard img {
        width: 50%;
    }
    
    div#tubeCard {
        padding: 20px;
    }

    /*Cart Slid Image Resize*/
    img#productImg {
        width: 30%;
    }

    .modal.fade.show .modal-dialog.modal-dialog-slideout {
        width: 350px;
        height: 100%;
    }


    /*=======================Services Component Start=======================*/
    div#exploreDivv {
        top: 25rem;
    }

    div#servContainer {
        padding-left: .5rem !important;
        padding-right: .5rem !important;
    }

    div#servicesHeadDiv {
        margin-top: -50px !important;
    }

    h2#servicesHead {
        font-size: 40px;
        padding-bottom: 10px !important;
    }


    div#serviceCard, #serviceCard2 {
        height: 450px !important;
        margin-bottom: 1rem !important;
    }
    
    
    /*=======================Services Component Start=======================*/


    /* Excellent Section */

    div#excellentContainer {
        background-image: url("../images/excelleng_bg.png");
        background-size: cover;
    }

    div#excellentDiv {
        margin-bottom: 3rem !important;
        margin-top: 10px !important;
    }

    img#downloadIcons {
        width: 120px;
    }

    p#servicesText {
        padding-top: 5px !important;
    }
    
    

    /* Cello Section */
    div#celloContainer {
        background-image: url("../images/cello_bg.png");
        background-size: cover;
    }

    img#CelloBot {
        width: 60%;
        margin-bottom: 2rem;
    }

    div#peopleContainer {
        margin-bottom: 4rem;
    }

    img#peopleCelloImg {
        text-align: center !important;
        display: none;
    }

    a#meet-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 10px 40px;
        border-radius: 30px;
        margin-right: 0rem;
    }


    /* Numbers Count Section */
    div#counterDiv {
        padding-top: 2rem !important;
    }

    /* Robocentre Section */
    img#roboCenImg {
        width: 100%; 
    }

    div#thatHolder2 {
        padding-top: 5rem !important;
    }

    div#contentHolder {
        width: 300px;
    }

    h3#joinText {
        font-size: 30px;
    }

    p#servicesText {
        font-size: 15px;
    }

    a#signup-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 5px 20px;
        border-radius: 30px;
        display: block;
    }




    /*=======================Footer Component Start=======================*/
    
    div#theFootContainer {
        margin-left: 0px !important;
    }

    div#theFootFields {
        margin-left: 0px !important;
        width: 300px !important;
    }

    div#subsMail {
        margin-left: 15px !important;
        padding-top: 10px !important;
    }

    /*=======================Footer Component End=======================*/






    /*=================About Page Start===============================*/

    img#bannerImg {
        height: 55vh;
        width: 100%;
    }

    div#bannerContent {
        width: 350px;
        position: absolute;
    }

    div#theVision {
        margin-top: -5rem !important;
    }

    div#theMission2 {
        margin-left: 0rem !important;
    }




    
    /*=============================Contact Page Start================================*/

    div#bannerContent2 {    
        padding-top: 10rem;
    }

    #gmap_canvas {
        width: 380px;
    }

    div#aboutBanner {
        height: 50vh;
    }

    p#contactText {
    color: #757575;
    }

    div#topCards {
        display: none;
    }

    div#theMap {
        margin-top: -7rem;
    }

    p#contactUs {
        font-weight: bold;
        color: #6666EA;
        margin-top: 2rem;
    }


    /*=============================Contact Page End================================*/



    /*==========================Support Page Start ===============================*/
    div#theLogoDiv {
        position: absolute;
        margin-left: 1rem;
    }

    img#supImg {
        width: 100%;
        height: 35vh;
    }

    span#supText {
        color: #fff;
        font-size: 20px;
        margin-left: 1rem;
    }
    
    div#supBannerContent {
        width: 350px;
        position: absolute;
        top: 5rem;
        left: 2rem;
        right: 2rem;
    }

    h1#supHead {
        font-size: 20px;
    }

    p#supSub {
        font-size: 18px;
        padding-top: 0px !important;
    }
    
    p#supportText {
    color: #757575;
    }
    /*==========================Support Page End ===============================*/


}

/*========================================= Mobile Responsiveness End ================================================*/








/*========================================= iPad Responsiveness Portrait Start ================================================*/
@media only screen  
  and (max-device-width: 820px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .goog-te-menu-value span:nth-child(1), 
    .goog-te-menu-value span:nth-child(2), 
    .goog-te-banner-frame.skiptranslate {
        display:none !important;
    }

    /* NAVBAR */

    Div#navCont {
        margin-left: 2rem;
    }

    img#theLogo {
        width: 100px;
    }

    #carouselExampleDark {
        margin-top: 2.5rem;
    }



    /* CAROUSEL*/

    div#carousel-caption1 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv {
        width: 300px;
    }

    div#carousel-caption2 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv2 {
        width: 300px;
    }

    div#carousel-caption3 {
        position: absolute;
        bottom: 0;
        top: 3rem;
        left:20;
        right:0;
    }

    div#headDiv3 {
        width: 300px;
    }

    h2#carouselHead {
        color: #fff;
        font-size: 25px;
    }

    div#pDiv {
        width: 300px;
        font-size: 14px;
        margin-top: -7rem !important;
        padding-top: 5rem;
    }

    div#btnDiv {
        width: 130px;
        margin-top: -1rem;
    }

    a#learnMore-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 4px 8px;
        border-radius: 30px;
        font-size: 12px;
        display: block;
    }

    a#learnMore-btn:hover {
        color: #fff;
        background-color: #ebb401;
    }

    .carousel-indicators {
        display: none;
    }



    /* SPONSORS*/
    div#sponsors {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .showcase-box > img {
        width: 40% !important;
    }

    div#youtubeBack {
        background-image: url("../images/youtube_bg.png");
        height: 75vh;   
    }
    
    #theVideo {
        width: 100%;
        height: 40vh;
        margin-top: 0rem !important;
        border-radius: 30px;
    }



    /*=======================Services Component Start=======================*/
    div#servContainer {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    div#serviceCard {
        height: 330px;
        margin-bottom: 3rem;
    }


    div#servicesHeadDiv {
        margin-top: -50px !important;
    }

    h2#servicesHead {
        font-size: 40px;
        padding-bottom: 10px !important;
    }


    
    
    /*=======================Services Component Start=======================*/


    /* Excellent Section */

    div#excellentContainer {
        background-image: url("../images/excelleng_bg.png");
        background-size: cover;
    }

    div#excellentDiv {
        margin-bottom: 3rem !important;
        margin-top: 10px !important;
    }

    img#downloadIcons {
        width: 120px;
    }

    p#servicesText {
        padding-top: 5px !important;
    }
    
    

    /* Cello Section */
    div#celloContainer {
        background-image: url("../images/cello_bg.png");
        background-size: cover;
    }

    img#CelloBot {
        width: 100%;
    }

    div#peopleContainer {
        margin-bottom: 4rem;
    }

    img#peopleCelloImg {
        text-align: center !important;
    }

    a#meet-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 10px 40px;
        border-radius: 30px;
        margin-right: 2rem;
    }


    /* Numbers Count Section */
    div#counterDiv {
        padding-top: 2rem !important;
    }

    /* Robocentre Section */
    img#roboCenImg {
        width: 100%; 
    }

    div#contentHolder {
        width: 300px;
    }

    h3#joinText {
        font-size: 30px;
    }

    p#servicesText {
        font-size: 15px;
    }

    a#signup-btn {
        color: #fff;
        background-color: #ffc400;
        text-decoration: none;
        padding: 5px 20px;
        border-radius: 30px;
        display: block;
    }




    /*=======================Footer Component Start=======================*/
    
    div#theFootContainer {
        margin-left: 20px !important;
        margin-right: 0px !important;
    }


    a#socialLink {
        text-decoration: none;
        padding: 9px 14px;
        border: 1px solid transparent;
        border-radius: 50px;
        font-size: 15px;
        margin-right: 7px;
    } 

    li#socialList > a {
        margin-top: 0rem !important;
    }

    input#thatName {
        border-radius: 0px;
    }
    
    input#thatMail {
        border-radius: 0px;
    }

    div#subsMail {
        padding-left: 0px !important;
    }

    button#subsBtn {
        border-radius: 0px !important;
    }


    /*=======================Footer Component End=======================*/






    /*=================About Page Start===============================*/

    img#bannerImg {
        height: 55vh;
        width: 100%;
    }

    div#bannerContent {
        width: 350px;
        position: absolute;
    }

    div#theMission {
        margin-top: -5rem !important;
    }

    div#theMission2 {
        margin-left: 0rem !important;
    }


}

/*========================================= iPad Responsiveness End ================================================*/