@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');

/*================GOogle Translator Start====================*/
.goog-te-menu-value span:nth-child(0),
.goog-te-menu-value span:nth-child(2),
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

div#google_translate_element div.goog-te-gadget-simple {
    border: .2px solid #0000dc;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 7px;
    background-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*================GOogle Translator End====================*/



html {
    scroll-behavior: smooth;
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', cursive;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
    background-color: #F9F9FF;
}



/*==================== First Section Start ===========================*/
/* Image Views Start */
.product-showcase-gallery .big-image {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 350px;
}

.product-showcase-gallery .big-image img {
    width: auto;
    height: auto;
    margin: auto;
    max-height: 350px;
}

.product-showcase-gallery .small-images ul {
    padding-left: 0;
    margin-bottom: 0;
    overflow: hidden;
}

.product-showcase-gallery .small-images ul li {
    margin-bottom: .5rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 87px;
}

.product-showcase-gallery .small-images img {
    width: 100px;
    margin: auto;
    height: auto;
    max-height: 87px;
    margin-left: 1.8rem;
    right: 0;
    margin-top: 1.3rem;
}

/*Image Views End*/

div#theDHLCard {
    border: none;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

h3#sec1Head {
    font-weight: bold;
    font-size: 21px;
}

img#door_deliveryImg {
    width: 50px;
    padding: 5px;
    border: 1px solid #656565;
    border-radius: 5px;
    opacity: 70%;
}

a#optionsBtn {
    text-decoration: none;
    color: #646464;
    border: 1px solid #646464;
    padding: 2px 8px;
    margin-right: 15px;
}

a#optionsBtn.active {
    color: #fff;
    background-color: #7575f0;
    border: 1px solid #7575f0;
}

a#optionsBtn:hover {
    color: #fff;
    background-color: #7575f0;
    border: 1px solid #7575f0;
}

button#cityBtn {
    border: 1px solid #fff !important;
}

button#cityBtn:hover {
    border: 1px solid #fff !important;
}

.fa-star {
    color: #E8E8E8;
    font-size: 20px;
}

.checked {
    color: #6666EA;
}

h3#thePrice {
    color: #6666EA;
    font-size: 24px;
    font-weight: bold;
}

small#smallPrice {
    color: red !important;
    font-weight: normal !important;
    text-decoration: line-through !important;
    font-size: 18px !important;
    margin-left: 1rem !important;
}

p#shortDesc {
    font-size: 16px;
    margin-bottom: 0px !important;
}

h3#sec1HeadDHL {
    font-weight: bold;
    font-size: 18px;
}

p#shortDescDHL {
    font-size: 14px;
    margin-bottom: 0px !important;
}

p#shortDesc2 {
    font-size: 16px;
}

a#shareIcons {
    text-decoration: none;
}

a#addToCart2 {
    text-decoration: none;
    color: #fff;
    background-color: #6666EA;
    border-radius: 30px;
    font-size: 18px;
    padding: 5px 20px;
    transition: all .3s;
    width: fit-content;
}

a#addToCart2:hover {
    color: #fff;
    background-color: #7575f0;
    padding: 3px 17px;
}

/*===================Purchase Modal=============*/
div#theHowToModal {}


h4#thankYou {
    color: #6666EA;
    font-weight: bold;
    font-size: 40px;
}

#dMoNumber {
    color: #6666EA;
    font-weight: bold;
    font-size: 24px;
}

/*Quantity Start*/
span {
    cursor: pointer;
}

.number {
    margin-top: 20px;
}

.minus,
.plus {
    width: 30px;
    height: 34px;
    background: #f2f2f2;
    border-radius: 0px;
    padding: 5px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

input {
    height: 34px;
    width: 80px;
    font-size: 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    display: inline-block;
    vertical-align: middle;
}

input#cartAddAdd {
    height: 30px;
    width: 40px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 0px;
    display: inline-block;
    vertical-align: middle;
}

#addToWishlist.active {
    font-size: 18px;
    color: red;
    border: 1px solid red;
    border-radius: 100%;
    padding: 1.5px 6px 0px 6px;
    position: relative;
    right: 2.5rem;
}

#addToWishlist {
    font-size: 18px;
    color: #646464;
    border: 1px solid #646464;
    border-radius: 100%;
    padding: 1.5px 6px 0px 6px;
    position: relative;
    right: 2.5rem;
}

#addToWishlist:hover {}


.number2 {
    margin-top: 5px;
}

.minus2,
.plus2 {
    width: 30px;
    height: 30px;
    font-size: 18px;
    background: #f2f2f2;
    border-radius: 0px;
    padding: 2px 0px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    color: #000;
}

/*Quantity End*/

/*Cart Slide In Content start*/

div#subTotalCardMain {
    border: 0px;
    border-radius: 30px;
    background-color: #F1F6FF;
}

div#subTotalCard {
    padding: 30px;
}

p#normalText {
    font-size: 16px;
    font-weight: bold;
}

h3#totalText {
    color: #E43556;
    font-size: 20px;
    font-weight: bold;

}

a#checkout-btn {
    background-color: #6666EA;
    color: #fff;
    padding: 5px 50px;
    border-radius: 30px;
    text-decoration: none;
    font-size: 20px;
}

/*Cart Slide In Content end*/


/*==================== First Section End ===========================*/

div#botBanner {
    background-image: url('../images/bot_banner.png');
    background-size: cover;
    height: 80vh;
}

/*==================== Whats in Box Section Start ===========================*/
h3#boxHead {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
}

ul#theList {
    margin-left: 4rem;
    color: #989898;
}

/*==================== Whats in Box Section End ===========================*/


/*==================== Amazing Features Section Start ===========================*/
h3#amazingHead {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
}

p#lowerText {
    color: #989898;
    font-size: 21px;
}

/*==================== Amazing Features Section End ===========================*/





/*==================== Different Forms Section Start ===========================*/
div#difFormsBg {
    background-image: url("../images/difSec_bg.png");
    height: 100%;
    background-size: cover;
}

h3#difHead {
    color: #fff;
    font-size: 42px;
    font-weight: bold;
}

p#difSub {
    color: #fff;
    font-size: 16px;
}

div#sameCardMain {
    background-color: #fff;
    border-radius: 30px;
    max-width: 300px;
    padding-bottom: 8px;
    border: 0px;
}

div#sameCard {
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(59, 184, 249, 1) 0%, rgba(5, 148, 225, 1) 100%);
    height: 200px;
    padding: 60px 20px 60px 20px;
}

div#sameCard2 {
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(255, 227, 136, 1) 0%, rgba(238, 183, 0, 1) 100%);
    height: 200px;
    padding: 60px 20px 60px 20px;
}

div#sameCard3 {
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(46, 236, 204, 1) 0%, rgba(6, 197, 166, 1) 100%);
    height: 200px;
    padding: 60px 20px 60px 20px;
}

p#sameText {
    font-weight: bold;
    font-size: 21px;
}

/*==================== Different Forms Section End ===========================*/


/*==================== Built With Section Start ===========================*/
h3#builtHead {
    font-size: 42px;
    font-weight: bold;
}

p#builtSub {
    font-size: 16px;
}

p#dHead {
    font-size: 21px;
    font-weight: bold;
}

/*==================== Built With Section End ===========================*/



/*==================== Mingo for Coding and Similar Section Start ===========================*/
h3#mingoHead {
    font-size: 42px;
    font-weight: bold;
}

p#mingoSub {
    font-size: 16px;
}

/*==================== Mingo for Coding and Similar Section End ===========================*/


/*==================== FAQ Section Start ===========================*/
h3#faqHead {
    font-weight: bold;
    font-size: 42px;
    color: #6666EA;
}

div#accordMain {
    border-radius: 50px 50px 50px 50px !important;
}

button#accord-btn {
    border-radius: 50px !important;
}

a#seeMore-btn {
    text-decoration: none;
    color: #6666EA;
    font-size: 16px;
}

/*
a#socialShare {
    text-decoration: none;
    color: #fff;
    background-color: #252363;
    padding: 10px 16px;
    margin: 8px;
    border-radius: 50px;
    font-size: 18px;
}
*/

/*==================== FAQ Section End ===========================*/



/*==================== Join 5000 Section Start ===========================*/
h3#joinHead {
    color: #fff;
    font-size: 42px;
}

a#dSignUp {
    color: #fff;
    background-color: #FFC400;
    font-size: 20px;
    border-radius: 50px;
    text-decoration: none;
    padding: 8px 70px;
    transition: all 0.3s;
}

a#dSignUp:hover {
    color: #fff;
    background-color: #ebb401;
    padding: 5px 67px;
}

/*==================== Join 5000 Section End ===========================*/