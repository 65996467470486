@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');

/*================GOogle Translator Start====================*/
.goog-te-menu-value span:nth-child(0),
.goog-te-menu-value span:nth-child(2),
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

div#google_translate_element div.goog-te-gadget-simple {
    border: .2px solid #0000dc;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 7px;
    background-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*================GOogle Translator End====================*/



html {
    scroll-behavior: smooth;
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Baloo 2', cursive;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
    background-color: #F9F9FF;
}

/*Step Indicator start*/
.form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
}

.form-header .stepIndicator.active {
    font-weight: 600;
}

.form-header .stepIndicator.finish {
    font-weight: 600;
    color: #6666EA;
}

.form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #CCCCF8;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
}

.form-header .stepIndicator.active::before {
    background-color: #6666EA;
    border: 3px solid #CCCCF8;
}

.form-header .stepIndicator.finish::before {
    background-color: #6666EA;
    border: 3px solid #CCCCF8;
}

.form-header .stepIndicator::after {
    content: "";
    position: absolute;
    right: 0%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
}

.form-header .stepIndicator.active::after {
    background-color: #6666EA;
}

/*Step Indicator end*/


#purchase_card {
    border-radius: 0px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


/*=====================Your Address Section Start======================*/
h3#checkHead {
    font-size: 21px;
}

p#checkSub {
    font-size: 16px;
}

div#infoCardMain {
    border-radius: 20px;
    border: 1px solid #ccccf8;
}

#theFName,
#theLName,
#theEmail,
#theNum,
#shipCountry,
#theRegion,
#shipCity,
#theHouseNum,
#theResAdd,
#postCode {
    box-shadow: none;
    border: 1px solid #ccccf8;
}

#infoDet {
    margin-bottom: 0px;
}

#change-btn {
    text-decoration: none;
    font-weight: bold;
}

div#infoCard {
    padding: 30px;
}

div#sumCardMain {
    border-radius: 20px;
    border: 0px;
}

div#sumCard {
    background-color: #F2F2FE;
    padding: 30px 15px;
    border-radius: 20px;
}

div#totalHolder {
    background-color: #fff;
    font-weight: bold;
    padding: 5px;
    border-radius: 50px;
}

label#checkLabel {
    font-size: 14px;
}

div#continueBtnDiv {
    float: right;
}

a#continueBtn,
#continueBtn2,
#confirmBtn {
    text-decoration: none;
    background-color: #6666EA;
    color: #fff;
    border-radius: 50px;
    padding: 4px 40px;
    font-size: 18px;
}

div#backBtnDiv {
    float: left;
}

a#backBtn,
#backBtn2,
#backBtn3 {
    text-decoration: none;
    color: #757575;
    border: 1px solid #757575;
    border-radius: 50px;
    padding: 4px 30px;
    font-size: 18px;
}

a#ccBtn,
#cashBtn,
#momoBtn,
#bankBtn {
    text-decoration: none;
    color: #757575;
    border: 1px solid #757575;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
}

a#ccBtn:hover,
#cashBtn:hover,
#momoBtn:hover,
#bankBtn:hover {
    border: 1px solid #ccccf8b9;
    background-color: #ccccf8b9;
}

/*=====================Your Address Section End======================*/