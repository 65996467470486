@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');


#account_card {
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#status_tab {
    color: #fff;
    background-color: #6666EA;
    padding: 3px 15px;
    width: fit-content;
    border-radius: 2px;
}

#see_details_btn {
    text-decoration: none;
    color: #6666EA;
}

#fakePrice {
    text-decoration: line-through;
    color: #646464;
    padding-top: 0px !important;
}

#buy_now_btn {
    color: #fff;
    background-color: #6666EA;
    padding: 5px 15px;
    width: fit-content;
    border-radius: 5px;
    text-decoration: none;
}

#remove_btn {
    color: #6666EA;
    font-size: 28px;
    text-decoration: none;
    margin-right: 3rem;
}

#continue_shopping {
    color: #fff;
    background-color: #6666EA;
    padding: 8px 15px;
    width: fit-content;
    border-radius: 5px;
    text-decoration: none;
}

#track_order {
    color: #fff;
    background-color: #6666EA;
}

#ddTextt {
    margin-bottom: 0px !important;
}

#wallet {
    color: #6666EA;
}

#new_address {
    color: #fff;
    background-color: #6666EA;
    padding: 5px 15px;
    width: fit-content;
    font-size: 12px;
    border-radius: 5px;
    text-decoration: none;
}

#address_cardy {
    border: none;
    border-radius: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#action_icons {
    color: #6666EA;
    font-size: 20px;
}